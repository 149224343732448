import { PaymentMethod } from '@hah/enums';
import * as Yup from 'yup';

export interface AddressBasic {
    street: string;
    city: string;
    zip: string;
    streetLineTwo?: string | undefined;
    state: string;
}
export interface PaymentInfoModel {
    billingAddress?: AddressBasic;

    cardholderName?: string;
    cardNumber?: string;
    expirationDate?: string;
    cvv?: string;
}
export interface BillingContactModel {
    firstName?: string;
    lastName?: string;
    email?: string;
    primaryPhone?: string;
    alternatePhone?: string;
}
export interface BookOrderValidationModel
    extends Omit<models.CheckoutPaymentViewModel, 'authOrLinkedJobsKey' | 'braintreeDeviceData' | 'jobNotes' | 'billingContact' | 'hhPhoneNumber' | 'billingAddress' | 'billingContactSameAsJobContact' | 'paymentMethod' | 'upgradeInsurance' | 'paymentMethodNonce' | 'payNow'> {
    //customerSignature: string;
    agreedToTos: boolean;
    paymentInfo: PaymentInfoModel;
    billingContact: BillingContactModel;
    billingContactSameAsJobContact?: boolean;
    paymentMethod?: PaymentMethod;
}

const paymentInfoValidation: Yup.ObjectSchema<PaymentInfoModel> = Yup.object({
    billingAddress: Yup.object({
        street: Yup.string().notRequired(),
        city: Yup.string().notRequired(),
        zip: Yup.string()
            .matches(/^[0-9]+$/, 'Zip Code must be only digits')
            .min(5, 'Zip Code must be exactly 5 digits')
            .max(5, 'Zip Code must be exactly 5 digits')
            .required('Zip Code required'),
        streetLineTwo: Yup.string().notRequired(),
        state: Yup.string().notRequired(),
    }),
}) as any;

const optionalPaymentInfoSchema: Yup.ObjectSchema<PaymentInfoModel> = Yup.object({
    billingAddress: Yup.object({
        street: Yup.string().notRequired(),
        city: Yup.string().notRequired(),
        zip: Yup.string().notRequired(),
        streetLineTwo: Yup.string().notRequired(),
        state: Yup.string().notRequired(),
    }),
    // cardholderName: Yup.string().notRequired(),
    // cardNumber: Yup.string().notRequired(),
    // expirationDate: Yup.string().notRequired(),
    // cvv: Yup.string().notRequired()
}) as any; // Lots of as any because strongly typed Yup object models vs possibly undefined values

const phoneRegex = /^(1\s*[-/.]?)?(\(([2-9]\d{2})\)|([2-9]\d{2}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT])\.?\s*(\d+))*$/;
export const contactValidationSchema: Yup.ObjectSchema<BillingContactModel> = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First Name Required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last Name Required'),
    primaryPhone: Yup.string().matches(phoneRegex, 'Phone Number appears invalid').required('Phone Number Required'),
    email: Yup.string().email('Invalid email').required('Email Required'),
    alternatePhone: Yup.string().matches(phoneRegex, 'Alternate Phone Number appears invalid').optional(),
});
export const optionalContactSchema: Yup.ObjectSchema<BillingContactModel> = Yup.object({
    firstName: Yup.string().notRequired(),
    lastName: Yup.string().notRequired(),
    primaryPhone: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    alternatePhone: Yup.string().notRequired(),
}) as any;

export const bookOrderValidationSchema: Yup.ObjectSchema<BookOrderValidationModel> = Yup.object({
    paymentInfo: Yup.object().when('paymentMethod', ([paymentMethod]) => {
        return paymentMethod === PaymentMethod.CreditCard ? paymentInfoValidation : optionalPaymentInfoSchema;
    }),
    billingContactSameAsJobContact: Yup.boolean(),
    paymentMethod: Yup.number(),
    billingContact: Yup.object().when('billingContactSameAsJobContact', ([sameAsContact]) => {
        return sameAsContact ? optionalContactSchema : contactValidationSchema;
    }),
    agreedToTos: Yup.boolean().oneOf([true], 'You must agree to the Terms of Service').required(),
});
