import * as React from 'react';

type CheckoutContextType = {
    discountCode: DiscountCodeState;
    setDiscountCode: React.Dispatch<React.SetStateAction<DiscountCodeState>>;
};

type DiscountCodeState = {
    showAddDiscountCode: boolean;
    appliedDiscountCode: string | undefined;
    pendingDiscountCode: string | undefined;
    discountCodeErrorMessage: string;
    discountAmount: number;
    formattedDiscountAmount: string | undefined;
};

export const CheckoutContext = React.createContext({} as CheckoutContextType);

export const CheckoutProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [discountCode, setDiscountCode] = React.useState<DiscountCodeState>({showAddDiscountCode: false, discountAmount: 0} as DiscountCodeState);

    return (
        <CheckoutContext.Provider value={{
            discountCode,
            setDiscountCode
        }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export const useCheckoutContext = () => React.useContext(CheckoutContext);
