import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';



interface HahFormikFieldProps<T extends {}> {
  name: keyof T;
  label?: string;
  placeholder?: string;
  autoComplete?: string;
  className?: string;
  parentName?: string;
  as?: React.ElementType<any>;
  rows?: number;
  type?: string;
  required?: boolean;
}
interface HahFormikSelectFieldProps<T extends {}> extends HahFormikFieldProps<T> {
  options: any[];
}

interface HahFormikCheckboxFieldProps<T extends {}> extends Omit<HahFormikFieldProps<T>, 'placeholder' | 'autoComplete' | 'label'> {
  labelNode?: React.ReactNode | string;
}

export const HahFormikField = <T extends {}>({ name, parentName, label, placeholder = label, autoComplete, className, as, rows, type = 'text', required }: HahFormikFieldProps<T>) => {

  const fullName = parentName ? `${parentName}.${name as string}` : name as string;

  const [inputProps, metaProps, helpers] = useField(fullName);

  const labelContent = required ? <><span>{label}</span> <span className='text-danger'>*</span></> : <span>{label}</span>;

  const formikBag = useFormikContext();
  
  React.useEffect(() => {
    if (formikBag.isSubmitting && metaProps.error) {
      helpers.setTouched(true);
    }
  }, [formikBag.isSubmitting, helpers, metaProps.error]);

  return <FloatingLabel label={labelContent} className="">
    <Form.Control id={fullName} className={className} required type={type} placeholder={placeholder} {...inputProps} isValid={metaProps.touched && !metaProps.error} isInvalid={metaProps.touched && !!metaProps.error} autoComplete={autoComplete} as={as} rows={rows} />
    <Form.Control.Feedback type="invalid">
      {metaProps.error}
    </Form.Control.Feedback>
  </FloatingLabel>;
};

export const HahFormikFieldSmall = <T extends {}>({ name, parentName, label, placeholder = label, autoComplete, className }: HahFormikFieldProps<T>) => {

  const fullName = parentName ? `${parentName}.${name as string}` : name as string;

  const [inputProps, metaProps] = useField(fullName);

  return <>
    <Form.Control id={fullName} className={className} required type="text" placeholder={placeholder} {...inputProps} isValid={metaProps.touched && !metaProps.error} isInvalid={metaProps.touched && !!metaProps.error} autoComplete={autoComplete} />
    <Form.Control.Feedback type="invalid">
      {metaProps.error}
    </Form.Control.Feedback>
  </>;
};

export function typedHahFormikField<T extends {}>() {
  return HahFormikField as React.FunctionComponent<HahFormikFieldProps<T>>;
}
export function typedHahFormikSelectField<T extends {}>() {
  return HahFormikSelectField as React.FunctionComponent<HahFormikSelectFieldProps<T>>;
}

export function typedHahFormikCheckbox<T extends {}>() {
  return HahFormikCheckbox as React.FunctionComponent<HahFormikCheckboxFieldProps<T>>;
}


export const HahFormikSelectField = <T extends {}>({ name, parentName, label, placeholder = label, autoComplete, options, className, required }: HahFormikSelectFieldProps<T>) => {

  const fullName = parentName ? `${parentName}.${name as string}` : name as string;

  const isStairsDropDown = fullName === 'loadingLocation.stairs' || fullName === 'unloadingLocation.stairs';

  const [inputProps, metaProps, helpers] = useField(fullName);

  const formikBag = useFormikContext();

  const labelContent = required ? <><span>{label}</span> <span className='text-danger'>*</span></> : <span>{label}</span>;

  React.useEffect(() => {
    if (formikBag.isSubmitting && metaProps.error) {
      helpers.setTouched(true);
    }
  }, [formikBag.isSubmitting, helpers, metaProps.error]);

  return <FloatingLabel label={labelContent} className={className}>
    <Form.Select id={fullName} className={isStairsDropDown ? 'pt-2' : ''} required placeholder={placeholder} {...inputProps} isValid={metaProps.touched && !metaProps.error} isInvalid={metaProps.touched && !!metaProps.error} autoComplete={autoComplete} aria-label={label}>
      {options.map(m => <option key={m.label} value={m.value}>{m.label}</option>)}
    </Form.Select>
    <Form.Control.Feedback type="invalid">
      {metaProps.error}
    </Form.Control.Feedback>
  </FloatingLabel>;
}


export const HahFormikCheckbox = <T extends {}>({ name, parentName, className, labelNode }: HahFormikCheckboxFieldProps<T>) => {

  const fullName = parentName ? `${parentName}.${name as string}` : name as string;

  const [inputProps, metaProps] = useField(fullName);


  return <Form.Check id={fullName} type="checkbox" label={labelNode} className={className}
    {...inputProps} checked={inputProps.value} isValid={metaProps.touched && !metaProps.error} isInvalid={metaProps.touched && !!metaProps.error}
    feedback={metaProps.error} feedbackType="invalid" />;
};

export const ErrorFocus = () => {
  // Get the context for the Formik form this component is rendered into.
  const { isSubmitting, isValidating, errors } = useFormikContext()

  React.useEffect(() => {
      // Get all keys of the error messages.
      const keys = Object.keys(errors)
      // Whenever there are errors and the form is submitting but finished validating.
      if (keys.length > 0 && isSubmitting) {
          // We grab the first input element that error by its name.
          let errorElement = document.querySelector(
              `[name="${keys[0]}"]`
          )
          // Ok we don't have a name, lets just grab the first element marked as
          if (!errorElement) {
            errorElement = document.querySelector(
              '.is-invalid'
            )
          }
          if (errorElement) {
            console.log('ErrorFocus errorElement display=' + window.getComputedStyle(errorElement).display)
              // Ok, if the element is hidden, get the first invalid message and scroll to that instead
              if (window.getComputedStyle(errorElement).display === 'none') {
                const invalidFeedback = document.querySelector('.is-invalid ~ .invalid-feedback');
                if (invalidFeedback) {
                    invalidFeedback.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              }
              else {
                // When there is an input, scroll this input into view.
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                (errorElement as HTMLElement).focus();
              }
          }
          else {
            console.log('ErrorFocus no errorElement - errors: ' + JSON.stringify(errors));
          }
      }
  }, [isSubmitting, isValidating, errors])

  // This component does not render anything by itself.
  return null
}
