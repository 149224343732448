import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useEffectOnlyOnceAsync } from '@hah/shared';
import { ExperimentIds } from '@hah/typewriter/constants';

export const QuoteUrgencyBanner = ({ isFormSubmitting }: {isFormSubmitting: boolean}) => {
    const optimizely = window.optimizely;
    const [inVariant, setInVariant] = useState(false);

    useEffectOnlyOnceAsync(async () => {
        await Hireahelper.Global.AbTestingUtils.bucketAndCheckForOptimizelyVariant(
            optimizely,
            ExperimentIds.TestPMG6519_QuoteUrgency_ApiName,
            ExperimentIds.TestPMG6519_QuoteUrgency_ID,
            [ExperimentIds.TestPMG6084_HomepageDefaultLFS_ID]
        ).then((variant) => {
            if (variant === ExperimentIds.TestPMG6519_QuoteUrgency_VariantID) {
                setInVariant(true);
            }
        });
    });

    if (!inVariant) {
        return null;
    }

    return <QuoteUrgencyTimer isFormSubmitting={isFormSubmitting} />;
};

export const QuoteUrgencyTimer = ({ isFormSubmitting }: {isFormSubmitting: boolean}) => {
    const TOTAL_SECONDS = 60 * 60; // 60 minutes
    const STORAGE_KEY = 'quoteTimerExpiry';
    const [timeRemaining, setTimeRemaining] = useState<string>('');
    const timerModalRef = useRef<HTMLDivElement>(null);
    const bootstrapModalRef = useRef<any>(null);

    const getOrCreateTimerExpiry = useCallback((): number => {
        const expiryString = localStorage.getItem(STORAGE_KEY);
        if (!expiryString) {
            const newExpiry = Date.now() + TOTAL_SECONDS * 1000;
            localStorage.setItem(STORAGE_KEY, newExpiry.toString());
            return newExpiry;
        }
        return parseInt(expiryString, 10);
    }, [TOTAL_SECONDS]);

    const formatTime = (ms: number): string => {
        const totalSeconds = Math.floor(ms / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const updateTimerDisplay = useCallback(() => {
        const expiry = getOrCreateTimerExpiry();
        const now = Date.now();
        const diff = expiry - now;

        if (diff <= 0) {
            setTimeRemaining('00:00');
            // Show modal using the modal ref and bootstrapModalRef
            if (timerModalRef.current) {
                if (!bootstrapModalRef.current) {
                    bootstrapModalRef.current = new (window as any).bootstrap.Modal(timerModalRef.current);
                }
                bootstrapModalRef.current.show();
            }
        } else {
            setTimeRemaining(formatTime(diff));
        }
    }, [getOrCreateTimerExpiry]);

    const handleRefresh = () => {
        localStorage.removeItem(STORAGE_KEY);
        location.reload();
    };

    useEffect(() => {
        // Check if the form is submitting and don't touch the timer since we'll reset it on success
        if (isFormSubmitting) {
            return;
        }
        updateTimerDisplay();
        const intervalId = setInterval(updateTimerDisplay, 1000);
        return () => clearInterval(intervalId);
    }, [isFormSubmitting, updateTimerDisplay]);

    return (
        <div className="row mt-3" id="quote-urgency-row">
            <div className="col">
                <h5 className="text-neutral-grey-900 text-center fw-semibold" style={{ fontSize: 18 }}>
                    Your reservation is saved for the next:
                </h5>
                <div className="rounded bg-neutral-grey-50 text-center">
                    <div className="text-neutral-grey-600 fs-2 fw-semibold py-2" id="timeRemaining">
                        {timeRemaining}
                    </div>
                </div>
                <div className="modal fade" id="timerModal" tabIndex={-1} aria-labelledby="timerModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" ref={timerModalRef}>
                    <div className="modal-auto modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ maxWidth: '360px' }}>
                            <div className="modal-body pt-3">
                                <div className="text-center">
                                    <i className="fas fa-clock fa-4x text-notification mb-2"></i>
                                </div>
                                <h3 className="modal-title mb-2">Your quote expired</h3>
                                <p className="mb-3">Your quote expired and needs to be refreshed. Availability changes quickly - let&apos;s confirm your move details.</p>
                                <button type="button" className="btn btn-notification-dark-10 btn-lg rounded-pill fs-6 w-100" id="refreshButton" onClick={handleRefresh}>
                                    Refresh my quote
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
