import { InternalReferralSourceEnum } from '@hah/enums';
import { createContext } from 'react';
import { CtaForm } from './components/CtaForm';

interface IHireahelperJsSettings {
    sitePhoneNumber: string;
}

declare global {
    interface Window {
        HireahelperJsSettings: IHireahelperJsSettings;
    }
}

export const CtaContext = createContext({ ctaConfig: { irs: InternalReferralSourceEnum.Unknown } as models.ReactCtaConfig });

function CtaApp({ ctaConfig }: { ctaConfig: models.ReactCtaConfig }) {

    return (
        <div className="App">
            <CtaContext.Provider value={{ ctaConfig }}>
                <CtaForm />
            </CtaContext.Provider>
        </div>
    );
}

export default CtaApp;
