import * as React from 'react';
export const StarDisplay = ({ rating, id, inline }: { rating: number; id: number; inline?: boolean; }) => {
    const stars = [];
    if (rating == 0) {
        return <React.Fragment>
            <span className="fal fa-star"></span>
            <span className="fal fa-star"></span>
            <span className="fal fa-star"></span>
            <span className="fal fa-star"></span>
            <span className="fal fa-star"></span>
        </React.Fragment>
    }
    for (let i = 1; i <= 5; i++) {
        if (rating >= i) {
            stars.push('fas fa-star')
        } else if (rating < i && rating > i - 1) {
            stars.push('fas fa-star-half-alt')
        } else {
            stars.push('fal fa-star')
        }
    }
    return <div className={inline ? 'd-inline' : ''} title={`Rating: ${rating}`}>
        {stars.map((x, i) => <span key={`review-${id}-rating-${i}`} className={x}></span>)}
    </div>
}