
interface PorchHomeWarrantyProps {
    title: string;
    subtitle: string;
}
export const PorchHomeWarranty = (props: PorchHomeWarrantyProps) => {
    return <div className="mb-4 p-3 rounded-3 shadow-sm bg-white">
        <div className="d-flex justify-content-start align-items-center gap-4">
            <img className='img-fluid' style={{ width: '52px', height: '52px' }} width="104" height="104" alt="Porch Logo" src="/_static/img/shared/logos/partner/porch-home-warranty.png" />
            <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-start gap-2">
                <div className="w-100 text-dark text-base fw-bold font-roboto">{props.title}</div>
                <div className="w-100 d-flex justify-content-start align-items-start gap-3">
                    <div className="flex-grow-1 text-secondary text-base fw-medium font-roboto">{props.subtitle}</div>
                </div>
            </div>
        </div>
    </div>
};
