import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CtaFormIds, HelpTypeNeededEnum, JobDetailsTypeOfHelpEnum } from '@hah/enums';
import * as iconLibrary from '@hah/icons';
import { DateTime } from 'luxon';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import store from 'store';
import { CtaContext } from '../Cta';
import '../assets/scss/cta-form.scss';
import useSubmitCtaForm from '../hooks/useSubmitCtaForm';
import { CtaFormErrors, CtaFormValues } from '../modules/ctaFormSchema';
import { CtaFormStacked } from './CtaForm-Stacked';

const shouldShowMpt = window.shouldShowMpt ?? true;

const getSchemaKey = (ctaFormId: CtaFormIds) => {
    switch (ctaFormId) {
        case CtaFormIds.FindMoversFooter:
            return 'cta-form-data-20230706-footer';
        default:
            return 'cta-form-data-20230706'; // this is so if we change storage schema between releases, it doesnt break clients local caches
    }
};


export const CtaForm = memo(() => {
    const { ctaConfig } = useContext(CtaContext);
    const schemaKey = getSchemaKey(ctaConfig.formId);

    // Used to determine if we should show the LFS Recommended banner
    const isRealHomepageCta = ctaConfig.formId === CtaFormIds.HomepageReal;

    const defaultDate = DateTime.local().plus({ weeks: 1 }).toLocaleString(DateTime.DATE_SHORT);

    // If you update these you need to update the link href in _LayoutBootstrap5Public.cshtml
    const mptImg = '/_static/img/public/react/cta-mpt.png?v=20230525';
    const laborImg = '/_static/img/public/react/cta-labor.png?v=20230525';

    //Set values either pulling from local storage or setting to defaults for first timers/fresh storage
    const [values, setValues] = useState<CtaFormValues>(() => {
        // get data from local storage or set some defaults
        const storedData: CtaFormValues = store.get(schemaKey) || {
            typeOfHelp: JobDetailsTypeOfHelpEnum.LoadingAndUnloading,
            helpTypeNeeded: HelpTypeNeededEnum.Unknown, // default to forcing them to choose
            loadingZip: '',
            loadingDate: defaultDate,
            unloadingZip: '',
            unloadingDate: defaultDate,
            truckSizeRange: 27,
        };

        if (!shouldShowMpt) {
            // i believe shouldShowMpt should trump everything
            return {...storedData, helpTypeNeeded: HelpTypeNeededEnum.LaborOnly };
        }

        // if we have an override value, use that
        if (ctaConfig.helpTypeOverride != HelpTypeNeededEnum.Unknown) {
            return { ...storedData, helpTypeNeeded: ctaConfig.helpTypeOverride };
        }

        // fall back to using our settings from stored data or the defaults
        return storedData;
    });

    const updateDateIfPast = (datePropertyName: keyof CtaFormValues) => {
        const dateValue = values[datePropertyName] as string;
        if (DateTime.fromFormat(dateValue, 'M/d/yyyy').endOf('day') < DateTime.now()) {
            setValues((prevValues) => ({ ...prevValues, [datePropertyName]: defaultDate }));
            store.set(schemaKey, { ...values, [datePropertyName]: defaultDate } as { [key: string]: any });
        }
    };

    updateDateIfPast('loadingDate');
    updateDateIfPast('unloadingDate');

    const [errors, setErrors] = useState<{ [key in keyof CtaFormValues]?: string }>({});
    const formRef = useRef<HTMLFormElement>(null);

    //reworked this to keep local storage in sync with the cta data
    const changeHandler = useCallback(
        (fieldName: keyof CtaFormValues, value: any) => {
            setValues((prevState) => {
                let updated = {
                    ...prevState,
                    [fieldName]: value,
                };

                let valueDate: Date | null;
                let loadDate: Date | null;
                let unloadDate: Date | null;

                const showToast = updated.typeOfHelp == JobDetailsTypeOfHelpEnum.LoadingAndUnloading && updated.helpTypeNeeded == HelpTypeNeededEnum.LaborOnly;

                switch (fieldName) {
                    case 'loadingDate':
                        valueDate = Hireahelper.Global.Utils.strToDateFlexible(value);
                        unloadDate = Hireahelper.Global.Utils.strToDateFlexible(prevState.unloadingDate);
                        if (valueDate && unloadDate && valueDate > unloadDate) {
                            updated = { ...updated, unloadingDate: value };
                            if (showToast) {
                                globalSitewide.toastInfo(null, 'We updated your unloading date.');
                            }
                        }
                        break;
                    case 'unloadingDate':
                        valueDate = Hireahelper.Global.Utils.strToDateFlexible(value);
                        loadDate = Hireahelper.Global.Utils.strToDateFlexible(prevState.loadingDate);
                        if (valueDate && loadDate && valueDate < loadDate) {
                            updated = { ...updated, loadingDate: value };

                            if (showToast) {
                                globalSitewide.toastInfo(null, 'We updated your loading date.');
                            }
                        }
                        break;

                    case 'helpTypeNeeded':
                        switch (value) {
                            case HelpTypeNeededEnum.MoversPlusTruck:
                                updated = { ...updated, typeOfHelp: JobDetailsTypeOfHelpEnum.LoadingAndUnloading };
                                break;
                            case HelpTypeNeededEnum.LaborOnly:
                                updated = { ...updated, typeOfHelp: JobDetailsTypeOfHelpEnum.Loading };
                                break;
                        }
                }

                //Update values in local storage
                store.set(schemaKey, updated);

                return updated;
            });
        },
        [schemaKey],
    );

    const errorHandler = useCallback((newErrors: CtaFormErrors) => {
        setErrors(newErrors);
    }, []);

    useEffect(() => {
        if (errors && formRef.current && formRef.current.querySelectorAll('.invalid').length > 0) {
            const el = formRef.current.querySelectorAll('.invalid')[0] as HTMLElement;
            el.focus();
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [errors]);

    const { handleSubmit } = useSubmitCtaForm({ ctaFormValues: values, errorHandler });

    const handleChangeHelpTypeNeeded = useCallback(
        (value: HelpTypeNeededEnum | undefined) => {
            changeHandler('helpTypeNeeded', value);
            setErrors({});
        },
        [changeHandler],
    );

    const showSelections = shouldShowMpt && (values.helpTypeNeeded === undefined || values.helpTypeNeeded === HelpTypeNeededEnum.Unknown);

    const ctaFormClassName = 'col-12 col-xl-auto mx-auto pb-0 pb-md-5 animated-lg bounceInRight';

    return (
        <form onSubmit={handleSubmit} noValidate className="" ref={formRef}>
            {shouldShowMpt && (
                <div className="row align-items-center justify-content-center select-service-row pb-3">
                    <div className="col col-lg-3 col-xxl-2">
                        <hr className={`max-width-hr ms-auto ${!showSelections ? 'd-lg-none' : 'd-block text-gray'}`} />
                    </div>
                    <div className="col-auto">
                        {showSelections ? (
                            <h2 className="text-center fs-4 fw-normal mb-0 pw-ctaServiceTitle">{ctaConfig.serviceTitle}</h2>
                        ) : (
                            <button type="button" className="btn btn-light rounded-pill border d-block d-lg-none py-1" onClick={() => handleChangeHelpTypeNeeded(undefined)}>
                                <FontAwesomeIcon icon={iconLibrary.faArrowLeft} className="text-notification-dark-10 pe-1" /> Go Back
                            </button>
                        )}
                    </div>
                    <div className="col col-lg-3 col-xxl-2">
                        <hr className={`max-width-hr me-auto ${!showSelections ? 'd-lg-none' : 'd-block text-gray'}`} />
                    </div>
                </div>
            )}
            {showSelections ? (
                <div className="animated-lg fadeIn pw-selectionScreen">
                    <div className="row justify-content-center">
                        <div className={`col col-lg-4 col-xxl-3 mb-3 ${isRealHomepageCta ? 'order-1 ps-2' : 'pe-2'}`}>
                            <div className={`cta-card px-2 px-md-4 border rounded h-100 position-relative ${isRealHomepageCta ? 'me-auto' : 'ms-auto'}`} onClick={() => handleChangeHelpTypeNeeded(HelpTypeNeededEnum.LaborOnly)}>
                                <FontAwesomeIcon icon={iconLibrary.faCheckCircle} className="text-notification-dark-10 fs-4 check-icon position-absolute top-0 end-0 mt-2 me-2" />
                                <div className="row h-100 d-flex align-items-end py-3">
                                    <div className="col text-center">
                                        <div className="mx-3">
                                            <img src={laborImg} height="180" width="180" className="img-fluid" alt="Illustration of Movers Only Loading and Unloading"></img>
                                        </div>
                                        <div className="mt-4 mb-2">
                                            <button type="button" className="btn btn-notification-dark-10 rounded-pill fw-semibold w-100 cta-card-btn">
                                                Movers Only
                                            </button>
                                        </div>
                                        <p className="text-gray mt-3 mb-2">Movers to help load/unload</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col col-lg-4 col-xxl-3 mb-3 ${isRealHomepageCta ? 'order-0 pe-2' : 'ps-2'}`}>
                            <div className={`cta-card px-2 px-md-4 border rounded h-100 position-relative ${isRealHomepageCta ? 'ms-auto cta-banner' : 'me-auto'}`} onClick={() => handleChangeHelpTypeNeeded(HelpTypeNeededEnum.MoversPlusTruck)}>
                                <FontAwesomeIcon icon={iconLibrary.faCheckCircle} className="text-notification-dark-10 fs-4 check-icon position-absolute top-0 end-0 mt-2 me-2" />
                                <div className="row h-100 d-flex align-items-end py-3">
                                    <div className="col text-center">
                                        <div className="mx-3">
                                            <img src={mptImg} height="180" width="180" className="img-fluid" alt="Illustration of Movers Loading a Truck"></img>
                                        </div>
                                        <div className="mt-4 mb-2">
                                            <button type="button" className="btn btn-notification-dark-10 rounded-pill fw-semibold w-100 cta-card-btn pw-moversPlusTruckButton">
                                                Movers + Truck
                                            </button>
                                        </div>
                                        <p className="text-gray mt-3 mb-2">Save more time with a truck</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className={ctaFormClassName}>
                        <CtaFormStacked typeOfHelp={values.typeOfHelp} helpTypeNeeded={values.helpTypeNeeded} title={ctaConfig.formTitle} values={values} errors={errors} onFieldChange={changeHandler} handleChangeHelpTypeNeeded={handleChangeHelpTypeNeeded} />
                    </div>
                </div>
            )}
        </form>
    );
});
