import { useEffect, useRef } from 'react';
import React from 'react';

export const useEffectOnMountAsync = (callback: (unloaded: () => boolean) => PromiseLike<void> | void, deps: React.DependencyList = []) => {
    const isFirstRun = useRef(true);
    const effectRef = useRef(callback);
    effectRef.current = callback;

    useEffect(() => {
        let unloaded = false;
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        effectRef.current(() => unloaded);
        return () => {
            unloaded = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

/**
 * Performs a React.useEffect callback only once; even if we're in DEV mode (which runs them twice normally).
 * Use this for code that truly just needs to be run once, doesn't have a way to teardown/etc - otherwise, use the regular React.useEffect with a teardown method provided.
 * @param callback The callback to run once.
 */
export const useEffectOnlyOnce = (callback: React.EffectCallback) => {
    const hasRun = React.useRef(false);

    React.useEffect(() => {
        if (hasRun.current) {
            return;
        }

        hasRun.current = true;

        // try and catch just so we have low level error handling; we're going to just re-throw it, but at least we'll log it here and be aware of a useEffect failing.
        // eslint-disable-next-line no-useless-catch
        try {
            callback();
        } catch (error) {
            // log to sentry and throw
            //sentryWrapper.logException(error, 'error', 'Exception during useEffectOnlyOnce', { hook: 'useEffectOnlyOnce' });
            throw error;
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

/**
 * Performs an async React.useEffect callback only once; even if we're in DEV mode (which runs them twice normally).
 * Use this for code that truly just needs to be run once, doesn't have a way to teardown/etc - otherwise, use the regular React.useEffect with a teardown method provided.
 * @param callback The callback to run once.
 */
export const useEffectOnlyOnceAsync = <T>(asyncFunction: () => Promise<T>): void => {
    const hasRun = React.useRef(false);

    React.useEffect(() => {
        if (hasRun.current) {
            return;
        }

        // mark as run immediately since we're dealing with async
        hasRun.current = true;

        const asyncFn = async () => {
            // try and catch just so we have low level error handling; we're going to just re-throw it, but at least we'll log it here and be aware of a useEffect failing.
            // eslint-disable-next-line no-useless-catch
            try {
                await asyncFunction();
            } catch (error) {
                // log to sentry and throw
                //sentryWrapper.logException(error, 'error', 'Exception during useEffectOnlyOnceAsync', { hook: 'useEffectOnlyOnceAsync' });
                throw error;
            }
        };

        asyncFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
